/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

const PERSONA_FIELD_KEY = {
  ID: 'id',
  NAME: 'title',
  CHANNEL: 'channels',
  DG_NAME: 'name',
  AGE: 'age',
  GENDER: 'gender',
  LOCATION: 'location',
  TOOLS: 'tools',
  JOB_TITLE: 'job_title',
  WEBSITE: 'website',
  SECTOR: 'sector',
  VENDOR_RESEARCH: 'vendor_research',
  INTEREST: 'interests',
  GOALS: 'goals',
  MARITAL_STATUS: 'marital_status',
  CHALLENGES: 'challenges',
  PAINT_POINT: 'paint_point',
  AVATAR: 'avatar',
  AVATAR_2: 'avatar_2',
  BIO: 'bio',
  CREATED_DATE: 'created_date',
  MODIFIED_DATE: 'modified_date',
  EDUCATION: 'education',
  DEMOGRAPHICS_SELECT: 'demographics_select',
  DEMOGRAPHICS_TYPE: 'demographics_type',
  INTERESTS_SELECT: 'interests_select',
  INTERESTS_TYPE: 'interests_type',
  BEHAVIORS_SELECT: 'behaviors_select',
  BEHAVIORS_TYPE: 'behaviors_type',
  AGE_FROM: 'age_from',
  AGE_TO: 'age_to',
  ALL_COUNTRIES: 'location_all_contries',
};

const PERSONA_RESPONSE_FIELD_KEY = {
  ID: 'id',
  NAME: 'title',
  CHANNEL: 'channels',
  DG_NAME: 'name',
  AGE: 'age',
  GENDER: 'gender',
  LOCATION: 'location',
  TOOLS: 'tools',
  JOB_TITLE: 'job_title',
  WEBSITE: 'website',
  SECTOR: 'sector',
  VENDOR_RESEARCH: 'vendor_research',
  INTEREST: 'interests',
  GOALS: 'goals',
  MARITAL_STATUS: 'marital_status',
  CHALLENGES: 'challenges',
  PAINT_POINT: 'paint_point',
  AVATAR: 'avatar',
  AVATAR_2: 'avatar_2',
  BIO: 'bio',
  CREATED_DATE: 'created_date',
  MODIFIED_DATE: 'modified_date',
  EDUCATION: 'education',
  DEMOGRAPHICS_SELECT: 'demographics_select',
  DEMOGRAPHICS_TYPE: 'demographics_type',
  INTERESTS_SELECT: 'interests_select',
  INTERESTS_TYPE: 'interests_type',
  BEHAVIORS_SELECT: 'behaviors_select',
  BEHAVIORS_TYPE: 'behaviors_type',
  AGE_FROM: 'age_from',
  AGE_TO: 'age_to',
  ALL_COUNTRIES: 'location_all_contries',
};

export { PERSONA_FIELD_KEY, PERSONA_RESPONSE_FIELD_KEY };
